// For carousel pause button. Still to be reconsidered ?!
import * as bootstrap from 'bootstrap'
import Parvus from 'parvus'
import de from 'parvus/src/l10n/de.js'
import * as bootstrapTable from 'bootstrap-table/src/bootstrap-table';

(function () {

  function initialize () {

    window.$ = jQuery;
    window.jQuery = jQuery;
    window.igSuggestions = $.igSuggestions;

    // Init Parvus
    const prvs = new Parvus({
      l10n: de,
      lightboxIndicatorIcon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/></svg>'
    });
    
    // Init Lightbox
    document.querySelectorAll( '[data-toogle="lightbox"]' ).forEach( el => el.addEventListener( 'click', Lightbox.initialize ));

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.querySelectorAll( '.needs-validation' )

    // Loop over them and prevent submission
    Array.prototype.slice.call( forms )
    .forEach( function ( form ) {
      form.addEventListener( 'submit', function ( event ) {
        if ( !form.checkValidity() ) {
          event.preventDefault()
          event.stopPropagation()
        }
        form.classList.add( 'was-validated' )
      }, false )
    });

    // Carousel Loop, add Pause-Buttons
    const allCarousels = [].slice.call( document.querySelectorAll( '.carousel' ));

    if ( allCarousels.length ) {
      allCarousels.forEach(( el, index ) => {
        let efCarousel = bootstrap.Carousel.getOrCreateInstance( el );
        let buttonPause = el.querySelector( '[data-ef-cycle="pause"]' );
        let buttonStart = el.querySelector( '[data-ef-cycle="cycle"]' );
        if ( buttonPause ) {
          buttonPause.addEventListener( 'click', event => {
              efCarousel.pause()
              buttonPause.classList.add( 'd-none' )
              buttonStart.classList.remove( 'd-none' )
              return event.preventDefault()
            }                                                                                               
          );
          buttonStart.addEventListener( 'click', event => {
              efCarousel.cycle()
              buttonPause.classList.remove( 'd-none' )
              buttonStart.classList.add( 'd-none' )
              return event.preventDefault()
            }                                                                                               
          );
        }
      })
    }

    var $table = $('*[data-toggle="table"]');
    $table.bootstrapTable({
      sortReset: true
    });

  }

  if (document.readyState != "loading") initialize();
  else if (document.addEventListener)
    document.addEventListener("DOMContentLoaded", initialize());
  else
    document.attachEvent("onreadystatechange", function () {
      if (document.readyState == "complete") initialize();
    });
})();
