import "@popperjs/core";
import { alert, button, carousel, collapse, dropdown, modal, popover, scrollspy, tab, toast, tooltip } from "bootstrap";

import "jquery/dist/jquery.js";
import "jquery-ui/ui/widgets/autocomplete.js";
import "Build/js/vendor/search/igSuggestions.js";
import "Build/js/init";

import "../scss/main.scss";

(function () {
    function initialize() {
        window.$ = jQuery;
        window.jQuery = jQuery;
        window.igSuggestions = $.igSuggestions;
    }

    if (document.readyState != "loading") initialize();
    else if (document.addEventListener) document.addEventListener("DOMContentLoaded", initialize());
    else
        document.attachEvent("onreadystatechange", function () {
            if (document.readyState == "complete") initialize();
        });
})();
