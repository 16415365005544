$.fn.igSuggestions = function(options) {

	var settings = $.extend({
		lang        : 'de',
		filterQuery : '*',
		url			: '/ig-api/rpc/search/suggest'
	}, options);

	var field = jQuery(this);
	var url = settings.url;
	var filterQuery = settings.filterQuery;
	var suggestRequest = null;
	var extraParams = {
		"indexKey"    : "multiplex",
		"fieldLimit"  : 5,
		"resultLimit" : 10,
		"fields"      : [ "keywords","anno.phrase","intergator.suggest.tags","intergator.suggest.logs","intergator.suggest.dym","filename"],
		"parameters"  : {
			"query"       : "*",
			"filterQuery" : filterQuery,
			"fieldFilters":[{
				"inclusive":true,
				"fields":[
					"dc.language:" + settings.lang
				]}
			]
		}
	};

	var autoposition = {
		of: field,
		my: "left top",
		at: "left bottom",
		collision: "none",
		offset: "0 -2"
	};

	field.bind("autocompleteopen", function(event, ui) {
		var widget = jQuery(this).autocomplete("widget");
		var w = field.outerWidth();
		widget.width(w - 2 - 4); // border :1px * 2 = 2; padding:2px * 2 = 4
	});

	field.autocomplete({
		position: autoposition,
		minLength : 2,
		delay     : 400,
		source: function(request, response) {
				suggestRequest = $.ajax({
					method  : 'POST',
					dataType: 'json',
					contentType: 'application/json',
					url     : url,
					data    : JSON.stringify(jQuery.extend({ phrase: request.term }, extraParams)),
					success : function(data) {
						if ( data && data[0] ) {
							response(jQuery.map(data, function(item) {
								var regex = new RegExp("(?![^&;]+;)(?!<[^<>]*)(" + request.term.replace(/([\^\$\(\)\[\]\{\}\*\.\+\?\|\\])/gi, "\\$1") + ")(?![^<>]*>)(?![^&;]+;)", "gi");
								return {
									label: item.value.raw.replace(regex, "<strong>$1</strong>"),
									value: item.value.raw
								}
							}));
						} else {
							response([]);
						}
					}
				});
			}
	}).data('ui-autocomplete')._renderItem = function(ul, item) {
		return jQuery("<li></li>")
			.data("item.autocomplete", item)
			.append('<a>' + item.label + '</a>')
			.appendTo(ul);
	};

	// Wenn man die Suche zu schnell auslöst, während Suggestions noch gerendert werden,
	// bleibt sie Auswahlbox fälschlicherweise stehen
	/*
	field.closest("form").submit(function(event) {
		field.autocomplete("destroy");
	});
	*/
}
